import React from 'react';
import styled from 'styled-components';

interface BigEmojiProps {
  text: string;
}

const BigEmoji = ({ text }: BigEmojiProps) => (
  <Span>{text}</Span>
);

const Span = styled.span.attrs({ className: 'd-block text-center' })`
  font-size: 96px;
`;

export default BigEmoji;
