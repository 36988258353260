import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import { RelayEnvironmentProvider } from 'react-relay';
import { fetchFn } from '@src/utils';
import { AuthProvider } from '@src/contexts/auth';
import { RecordsProvider } from '@src/contexts/records';
import GuestRoute from '@src/components/GuestRoute';
import ProtectedRoute from '@src/components/ProtectedRoute';
import Layout from '@src/components/Layout';
import Home from '@src/components/Home';
import Login from '@src/components/Login';
import RecordDisplay from '@src/components/RecordDisplay';
import 'bootstrap/scss/bootstrap.scss';

((doc: Document) => {
  const network = Network.create(fetchFn);
  const store = new Store(new RecordSource());
  const environment = new Environment({
    network,
    store,
  });

  const router = createBrowserRouter([
    {
      element: (
        <AuthProvider>
          <RecordsProvider>
            <Layout>
              <Outlet />
            </Layout>
          </RecordsProvider>
        </AuthProvider>
      ),
      children: [
        {
          path: '/login',
          element: (
            <GuestRoute>
              <Login />
            </GuestRoute>
          ),
        },
        {
          path: '/',
          element: (
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          ),
        },
        {
          path: "/r/:recordId",
          element: (
            <ProtectedRoute>
              <RecordDisplay />
            </ProtectedRoute>
          ),
        },
      ],
    },
  ]);

  const container = doc.getElementById('app');
  const root = createRoot(container!);

  root.render(
    <React.StrictMode>
      <RelayEnvironmentProvider environment={environment}>
        <RouterProvider router={router} />
      </RelayEnvironmentProvider>
    </React.StrictMode>
  );
})(document);
