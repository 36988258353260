import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useAuth } from '@src/contexts/auth';

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const location = useLocation();
  const { status, redirectToAuth } = useAuth();
  const isLoggedOut = status !== 'authenticated';

  useEffect(() => {
    if (isLoggedOut) {
      redirectToAuth(location);
    }
  }, [isLoggedOut]);

  return isLoggedOut ? null : children;
};

export default ProtectedRoute;
