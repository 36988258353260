import { createGlobalStyle } from 'styled-components';
 
const GlobalStyles = createGlobalStyle`
  html, body, #app {
    width: 100%;
    height: 100%;
    font-size: .9rem;
    background-color: #EEE;
    color: #444;
  }
`;
 
export default GlobalStyles;
