import React from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BigEmoji from './BigEmoji';

const Home = () => (
  <Row className="h-100 align-items-center">
    <Col>
      <IconContainer>
        <BigEmoji text="🥰" />
      </IconContainer>
    </Col>
  </Row>
);

const IconContainer = styled.div.attrs({ className: 'h-100' })`
`;

export default Home;
