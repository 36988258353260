import React from 'react';
import Container from 'react-bootstrap/Container';
import GlobalStyles from './GlobalStyles';

interface LayoutProps {
  children: JSX.Element;
}

const Layout = ({ children }: LayoutProps) => (
  <>
    <GlobalStyles />
    <Container fluid className="h-100 p-4">
      {children}
    </Container>
  </>
);

export default Layout;
