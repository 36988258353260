import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useAuth } from '@src/contexts/auth';
import BigEmoji from './BigEmoji';

const Login = () => {
  const { status, error, attemptLogin } = useAuth();
  const isAuthenticating = status === 'authenticating';
  const hasError = error != undefined;
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    const formData = new FormData(ev.currentTarget);
    const password = formData.get('password') as string;

    attemptLogin(password);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Row className="h-100 align-items-center">
      <Col>
        <Form className="d-grid" onSubmit={handleSubmit}>
          <div className="mb-4">
            <BigEmoji text="🤔" />
          </div>
          <Form.Group className="mb-3 text-center">
            <Form.Label htmlFor="password-field" className="mb-4">
              <strong>Insira a palavra-chave capaz de desvendar todos os mistérios...</strong>
            </Form.Label>
            <Form.Control id="password-field" name="password" required disabled={isAuthenticating} isInvalid={hasError} ref={inputRef} className="text-center" />
            {hasError && (
              <span className="text-danger">{error}</span>
            )}
          </Form.Group>
          <Button size="lg" type="submit" disabled={isAuthenticating}>{ isAuthenticating ? 'Entrando...' : 'Entrar'}</Button>
        </Form>
      </Col>
      {isAuthenticating && (
        <LoadingLayer />
      )}
    </Row>
  );
};

const LoadingLayer = styled.div.attrs({ className: 'position-absolute w-100 h-100' })`
  background: rgba(255, 255, 255, .7);
`;

export default Login;
