import { FetchFunction } from 'relay-runtime';
import { Base64 } from 'js-base64';

let globalPassword: string | undefined;
export const setGlobalPassword = (password: string) => {
  globalPassword = password;
};
export const clearGlobalPassword = () => {
  globalPassword = undefined;
};

declare const __GRAPHQL_API_URL__: string;

export const fetchFn: FetchFunction = async (request, variables) => {
  const password = globalPassword ?? retrievePasswordFromStorage() ?? null;

  const headers = new Headers();
  headers.set('Content-Type', 'application/json');

  if (password) {
    const base64 = Base64.encode(`:${password}`);
    headers.set('Authorization', `Basic ${base64}`);
  }

  const response = await fetch(__GRAPHQL_API_URL__, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      password,
      query: request.text,
      variables,
    }),
  });

  if (response.status != 200) {
    throw `Invalid http status: ${response.status}`;
  }

  return await response.json();
};

const localStoragePasswordKey = 'timelovePassword';

export const retrievePasswordFromStorage = () => {
  return window.localStorage[localStoragePasswordKey] ?? null;
};

export const setPasswordIntoStorage = (password: string) => {
  window.localStorage[localStoragePasswordKey] = password;
};

export const addSecondsToDate = (dt: Date, amount: number) => {
    const newDt = new Date(dt);
    newDt.setSeconds(newDt.getSeconds() + amount);
    return newDt;
};

export const addDaysToDate = (dt: Date, amount: number) => {
    const newDt = new Date(dt);
    newDt.setDate(newDt.getDate() + amount);
    return newDt;
};
