import React, { useMemo, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { useAuth } from '@src/contexts/auth';
import { useRecords } from '@src/contexts/records';
import Record from '@src/components/Record';

const RecordDisplay = () => {
  const { user } = useAuth();
  const { recordId } = useParams();
  const { loaded, followingRecords, isLoadingRecords, loadMore } = useRecords(recordId ?? '');
  const filteredRecords = useMemo(() => {
    return followingRecords.filter(rec => rec.type != 'GOOGLEPHOTOSAPI' || rec.hash === recordId);
  }, [loaded, followingRecords.length]);
  const edgeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener('scroll', checkAutoloadEdge);
    return () => window.removeEventListener('scroll', checkAutoloadEdge);
  }, [loadMore]);

  useEffect(() => {
    checkAutoloadEdge();
  });

  const checkAutoloadEdge = () => {
    if (edgeRef.current && !isLoadingRecords) {
      const rects = edgeRef.current.getClientRects();
      const { innerHeight } = window;
      const { top } = rects[0]!;

      if (!isLoadingRecords && top < innerHeight * 2) {
        loadMore();
      }
    }
  };

  return (
    <Row className="h-100">
      <Col>
        {filteredRecords.map(rec => (
          <Record key={rec.id} record={rec} isOwn={rec.author === user!.code} isFeatured={rec.hash === recordId} />
        ))}
        {isLoadingRecords && (
          <div className="m-4 text-center">
            <Spinner />
          </div>
        )}
        <Edge ref={edgeRef} />
      </Col>
    </Row>
  );
};

const Edge = styled.div`
  height: 200px;
`;

export default RecordDisplay;
