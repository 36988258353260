import React, { createContext, useContext, useEffect, useState } from 'react';
import { graphql } from 'relay-runtime';
import { useRelayEnvironment, fetchQuery } from 'react-relay';
import { Location, useNavigate } from "react-router-dom";
import { setGlobalPassword, clearGlobalPassword, retrievePasswordFromStorage, setPasswordIntoStorage } from '@src/utils';
import type { authAuthenticatedUserQuery, authAuthenticatedUserQuery$data } from './__generated__/authAuthenticatedUserQuery.graphql';

export type AuthStatus = 'authenticated' | 'authenticating' | 'unauthenticated';
export type User = authAuthenticatedUserQuery$data['GetAuthenticatedUser'];

interface AuthContextValueType {
  status: AuthStatus;
  error?: string;
  user?: User;
  attemptLogin: (str: string) => void;
  redirectToAuth: (loc: Location) => void;
  redirectBack: () => void;
}

const AuthContext = createContext<AuthContextValueType>({
  status: 'unauthenticated',
  attemptLogin: () => {},
  redirectToAuth: (_: Location) => {},
  redirectBack: () => {},
});

const GetAuthenticatedUserQuery = graphql`
  query authAuthenticatedUserQuery {
    GetAuthenticatedUser {
      code
      name
    }
  }
`;

interface AuthProviderProps {
  children: JSX.Element;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const environment = useRelayEnvironment();
  const [status, setStatus] = useState<AuthStatus>('unauthenticated');
  const [error, setError] = useState<string | undefined>(undefined);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [redirectHref, setRedirectHref] = useState<Location | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    const password = retrievePasswordFromStorage();
    if (password) {
      attemptLogin(password);
    }
  }, []);

  const attemptLogin = async (password: string) => {
    setStatus('authenticating');

    let data: authAuthenticatedUserQuery$data | undefined;
    // TODO should improve this
    try {
      setGlobalPassword(password);
      data = await fetchQuery<authAuthenticatedUserQuery>(environment, GetAuthenticatedUserQuery, {}).toPromise();
      clearGlobalPassword();
    } catch (err) {
      setError('Palavra-cache incorreta! 🙈');
      setStatus('unauthenticated');
    }

    if (data) {
      setUser(data.GetAuthenticatedUser);
      setPasswordIntoStorage(password);

      setError(undefined);
      setStatus('authenticated');
    } else {
      setStatus('unauthenticated');
    }
  };

  const redirectToAuth = (loc: Location) => {
    setRedirectHref(loc);
    navigate('/login');
  };

  const redirectBack = () => {
    navigate(redirectHref ?? '/');

    if (redirectHref) {
      setRedirectHref(undefined);
    }
  };

  return (
    <AuthContext.Provider value={{
      status,
      error,
      user,
      attemptLogin,
      redirectToAuth,
      redirectBack,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const value = useContext(AuthContext);
  return value;
};
