import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { graphql } from 'relay-runtime';
import { useRelayEnvironment, fetchQuery } from 'react-relay';
import { addDaysToDate, addSecondsToDate } from '@src/utils';
import type { recordsGetRecordsQuery, recordsGetRecordsQuery$data } from './__generated__/recordsGetRecordsQuery.graphql';
import type { recordsGetRecordQuery, recordsGetRecordQuery$data } from './__generated__/recordsGetRecordQuery.graphql';

const AUTOLOAD_INTERVAL_DAYS = 3;

export type RecordItem = recordsGetRecordQuery$data['GetRecord'];
export type Record = recordsGetRecordsQuery$data['GetRecords']['records'][number];

interface RecordsContextValueType {
  initialLoaded: {[key: string]: boolean};
  timelines: {[key: string]: Record[]};
  loading: {[key: string]: boolean};
  loadRecordData: (_: string) => void;
  loadMoreRecordTimeline: (_: string) => void;
}

const RecordsContext = createContext<RecordsContextValueType>({
  initialLoaded: {},
  timelines: {},
  loading: {},
  loadRecordData: (_: string) => {},
  loadMoreRecordTimeline: (_: string) => {},
});

const GetRecordQuery = graphql`
  query recordsGetRecordQuery ($hash: String!) {
    GetRecord(hash: $hash) {
      hash
      registeredAt
    }
  }
`;

const GetRecordsQuery = graphql`
  query recordsGetRecordsQuery ($from: DateTime!, $to: DateTime!, $cursor: String) {
    GetRecords(from: $from, to: $to, cursor: $cursor) {
      records {
        id
        hash
        type
        author
        content {
          text
          url
          files {
            name
            variant
            url
          }
        }
        registeredAt
      }
      cursor
    }
  }
`;

interface RecordsProviderProps {
  children: JSX.Element;
}

export const RecordsProvider = ({ children }: RecordsProviderProps) => {
  const environment = useRelayEnvironment();
  const [initialLoaded, setInitialLoaded] = useReducer((carry: {[key: string]: boolean}, recordId: string) => ({ ...carry, [recordId]: true }), {});
  const [records, setRecord] = useReducer((carry: {[key: string]: RecordItem}, rec: RecordItem) => ({ ...carry, [rec.hash]: rec }), {});
  const [timelines, addTimelineRecords] = useReducer((carry: {[key: string]: Record[]}, info: { recordId: string; records: Record[] }) => {
    const currRecords = carry[info.recordId] ?? [];
    const recordKeys = currRecords.reduce((carry: {[key: string]: boolean}, rec) => ({ ...carry, [rec.id]: true }), {});
    const newRecords = info.records.filter(rec => !recordKeys[rec.id]);

    return {
      ...carry,
      [info.recordId]: [...currRecords, ...newRecords],
    };
  }, {});
  const [lastLoadDates, setLastLoadDates] = useReducer((carry: {[key: string]: string}, info: { recordId: string; date: string }) => ({ ...carry, [info.recordId]: info.date }), {});
  const [loading, setLoading] = useReducer((carry: {[key: string]: boolean}, info: { recordId: string; loading: boolean }) => ({ ...carry, [info.recordId]: info.loading }), {});

  const loadRecordData = async (hash: string) => {
    const isLoaded = initialLoaded[hash] ?? false;
    if (isLoaded) {
      return;
    }

    const data = await fetchQuery<recordsGetRecordQuery>(environment, GetRecordQuery, { hash }).toPromise();
    if (data) {
      setRecord(data.GetRecord);
    }

    setInitialLoaded(hash);

    await loadMoreRecordTimeline(hash, data?.GetRecord);
  };

  const loadMoreRecordTimeline = async (hash: string, rec?: RecordItem) => {
    if (loading[hash]) {
      return;
    }

    const record = rec ?? records[hash];
    if (!record) {
      return;
    }

    const from = lastLoadDates[hash] ? addSecondsToDate(new Date(lastLoadDates[hash]!), 1) : new Date(record.registeredAt); // Add one second to avoid the case where a record might exists in the exact seconds, making it duplicate
    const to = addDaysToDate(from, AUTOLOAD_INTERVAL_DAYS);

    if (from.getTime() > Date.now()) {
      return; // The from date is already in the future, so there is more items to load
    }

    setLoading({ recordId: hash, loading: true });

    let cursor: string | undefined;
    while (true) {
      const data = await fetchQuery<recordsGetRecordsQuery>(environment, GetRecordsQuery, { from, to, cursor }, {
        networkCacheConfig: { force: true },
      }).toPromise();

      if (data) {
        addTimelineRecords({ recordId: hash, records: [...data.GetRecords.records] });

        if (data.GetRecords.cursor) {
          cursor = data.GetRecords.cursor;
          continue;
        }
      }

      break;
    }

    setLastLoadDates({ recordId: hash, date: to.toISOString() });
    setLoading({ recordId: hash, loading: false });
  };

  return (
    <RecordsContext.Provider value={{
      initialLoaded,
      timelines,
      loading,
      loadRecordData,
      loadMoreRecordTimeline,
    }}>
      {children}
    </RecordsContext.Provider>
  );
};

export const useRecords = (recordId: string) => {
  const { initialLoaded, timelines, loading, loadRecordData, loadMoreRecordTimeline } = useContext(RecordsContext);

  useEffect(() => {
    loadRecordData(recordId);
  }, [recordId]);

  const loaded = initialLoaded[recordId] ?? false;
  const followingRecords = timelines[recordId] ?? [];
  const isLoadingRecords = loading[recordId] ?? false;

  return {
    loaded,
    followingRecords,
    isLoadingRecords,
    loadMore: () => loadMoreRecordTimeline(recordId),
  };
};
