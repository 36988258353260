import { useEffect } from 'react';
import { useAuth } from '@src/contexts/auth';

interface GuestRouteProps {
  children: JSX.Element;
}

const GuestRoute = ({ children }: GuestRouteProps) => {
  const { status, redirectBack } = useAuth();
  const isLoggedIn = status === 'authenticated';

  useEffect(() => {
    if (isLoggedIn) {
      redirectBack();
    }
  }, [isLoggedIn]);

  return isLoggedIn ? null : children;
};

export default GuestRoute;
