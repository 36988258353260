import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Record as RecordType } from '@src/contexts/records';

interface RecordProps {
  record: RecordType;
  isOwn: boolean;
  isFeatured: boolean;
}

const locale = 'pt-BR';

const Record = (props: RecordProps) => {
  const { record } = props;
  const localizedRegisterDate = useMemo(() => {
    const dt = new Date(record.registeredAt);
    return dt.toLocaleString(locale, { dateStyle: 'short', timeStyle: 'short' });
  }, [record.registeredAt]);

  return (
    <MessageWrapper {...props}>
      <MessageContainer {...props}>
        {(() => {
          switch (record.type) {
            case 'WHATSAPPCHAT':
              return (
                <WhatsappChatContent {...props} />
              );
            case 'GOOGLEPHOTOSAPI':
              return (
                <GooglePhotosApiContent {...props} />
              );
            default:
              return (
                <ErrorMessage>
                  <em>Conteúdo desconhecido</em>
                  <span> 😖</span>
                </ErrorMessage>
              );
          }
        })()}
        <RegisterDate>{localizedRegisterDate}</RegisterDate>
      </MessageContainer>
    </MessageWrapper>
  );
};

const WhatsappChatContent = ({ record }: RecordProps) => {
  const { text } = record.content;
  const splittedText = useMemo(() => (text ?? '').split("\n"), [text]);

  if (text === '<Media omitted>') {
    return (
      <ErrorMessage>
        <em>Mídia indisponível</em>
        <span> 🖼️</span>
      </ErrorMessage>
    );
  }

  return (
    <div>
      {splittedText.map((part, idx) => (
        <React.Fragment key={idx}>
          {idx > 0 && (
            <br />
          )}
          {part}
        </React.Fragment>
      ))
      }
    </div>
  );
};

const VARIANT_SIZE = '600';

const GooglePhotosApiContent = ({ record }: RecordProps) => {
  const url = record.content.files?.find(f => f.variant === VARIANT_SIZE)?.url;

  return (
    <a href={record.content.url ?? undefined} target="_blank">
      <MainImage src={url} />
    </a>
  );
};

const MessageWrapper = styled.div.attrs<RecordProps>(({ isOwn }) => ({
  className: ['mb-2', isOwn ? 'text-end' : 'text-start'].join(' '),
}))<RecordProps>`
`;

const MessageContainer = styled.div.attrs<RecordProps>(() => ({
  className: 'd-inline-block p-2 text-start',
}))<RecordProps>`
  position: relative;
  max-width: ${props => props.isFeatured ? 'none' : '90%'};
  border-radius: ${props => props.isOwn ? '8px 0 8px 8px' : '0 8px 8px'};
  background: ${props => props.isOwn ? 'linear-gradient(royalblue, #517dff)' : 'linear-gradient(#888, #959595)'};
  /*background: ${props => props.isOwn ? 'linear-gradient(#dc3545, #e94252)' : 'linear-gradient(#888, #959595)'};*/
  color: #FFF;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: default;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    ${props => props.isOwn ? 'right' : 'left'}: -8px;
    display: block;
    width: 0;
    height: 0;
    border: 6px solid ${props => props.isOwn ? 'royalblue' : '#888'};
    ${props => props.isOwn ? 'border-right-color' : 'border-left-color'}: transparent;
    border-bottom-color: transparent;
  }
`;

const RegisterDate = styled.small.attrs({
  className: 'd-block text-end mt-1',
})`
  font-size: .75rem;
  color: #DDD;
`;

const MainImage = styled.img.attrs({
  className: 'd-block',
})`
  max-width: 100%;
  min-width: 200px;
  min-height: 100px;
  border-radius: 6px;
`;

const ErrorMessage = styled.small``;

export default Record;
